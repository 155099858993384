<template>
    <SectionResetPassword />
</template>

<script>
    import SectionResetPassword from '@/components/SectionResetPassword.vue'
    import {mapGetters} from "vuex";

    export default {
        name: 'LoginPage',
        props: [],
        data() {
            return {}
        },
        mounted() {
        },
        components: {
            SectionResetPassword
        },
        methods: {
        },
        computed: {
            ...mapGetters([
                "apiToken"
            ]),
        },
    }
</script>
