<template>
    <section class="section section_type_form-page is_new-design">
        <div class="section__wrapper wrapper wrapper_width_md">
            <!--<div class="section__logo-wrap">
                <img :src="customize.login_logo">
            </div>-->
            <div class="section__form-wrap">
                <form class="section__form pfull__52" @submit.prevent>
                    <h1 class="section__title mb__16">
                        {{ $t('Create new password') }}
                    </h1>

                    <h3 class="form-subtitle mb__24">
                        {{ $t('Please enter your new password') }}
                    </h3>
                    <FieldGroup_password
                        :field-group_class="'mb__16'"
                        :id="'password'"
                        :optional_hidden_field="true"
                        :label="$t('Password')"
                        :placeholder="$t('Enter password')"
                        :error="errors.password"
                        v-model.trim="password"
                    />
                    <FieldGroup_password
                        :field-group_class="'mb__16'"
                        :optional_hidden_field="true"
                        :id="'confirm-password'"
                        :label="$t('Confirm password')"
                        :placeholder="$t('Confirm password')"
                        :error="errors.confirm_password"
                        v-model.trim="confirm_password"
                    />

                    <div class="horizontal-line">
                        <button class="button button_view_base button_theme_primary button_size_lg button_width_100p mr__8"
                                @click.prevent="onSubmit"
                        >
                            {{ $t('buttons.Confirm') }}
                        </button>
                        <template v-if="!isLite">
                        <button class="button button_view_base button_theme_white button_size_lg"
                                type="submit"
                        >
                            {{ $t('buttons.signin') }}
                        </button>
                        </template>
                    </div>

                    <div class="section__horizontal-line horizontal-line"
                         v-if="false"
                    >
                        <div></div>
                        <router-link class="section__link link" to="/login">{{ $t('Sign In') }}</router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    import { required, minLength, sameAs } from 'vuelidate/lib/validators'

    export default {
        name: 'SectionResetPassword',
        props: [],
        data() {
            return {
                password: '',
                confirm_password: '',
                resetToken: '',
                errors: {},
            }
        },
        components: {},
        validations: {
            resetToken: { required },
            password: { required, minLength: minLength(6) },
            confirm_password: { required, sameAsPassword: sameAs('password') }
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isLite",
            ]),
        },
        methods: {
            onSubmit() {
                //console.log('onSubmit', this)
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.errors = {}
                    if(!this.$v.resetToken.required) {
                        //this.errors = {...this.errors, password: 'Password cannot be blank.'}
                        this.errors.resetToken = this.$t('Token cannot be blank')
                    }
                    if(!this.$v.password.required) {
                        this.errors.password = this.$t('Password is required')
                    }else
                    if(!this.$v.password.minLength){
                        this.errors.password = this.$t('Password must be minimum 6 characters')
                    }
                    if(!this.$v.confirm_password.required) {
                        this.errors.confirm_password = this.$t('Confirm Password is required')
                    }else
                    if(!this.$v.confirm_password.sameAsPassword){
                        this.errors.confirm_password = this.$t('Password and Confirm Password should match')
                    }
                    return false // stop here if form is invalid
                }
                this.$api.auth.resetPassword(this.password, this.confirm_password, this.resetToken)
                .then((response) => {
                    //console.log(response);
                    if(response.data && !response.data.error){
                        this.errors = {}
                        this.$router.push('/login')
                    }else{
                        console.warn(response.data);
                        this.errors = {...response.data.errors}
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.errors = {password: ' ', confirm_password: ' ', resetToken: ' '}
                });
            }
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>